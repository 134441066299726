import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FAQItem from "../components/FAQItem"
import ExpectedValueCalculator from "../components/tools/ExpectedValueCalculator"

const LotteryExpectedValueCalculator = ({ data, location }) =>
  <Layout location={location} title={data.site.siteMetadata.title}>
    <SEO title="Lottery Expected Value Calculator"
         description="Is it worth playing the lottery? What is the ROI of buying a lottery ticket? Is there a limit where the jackpot is big enough where it actually makes sense to put your money into lottery tickets? Does your chances grow if you buy more tickets?" />
    <h1>Lottery Expected Value Calculator</h1>
    <p>
      Is it worth playing the lottery? What is the ROI of buying a lottery ticket? Is there a limit where the jackpot is big enough where it actually makes sense to put your money into lottery tickets? Does your chances grow if you buy more tickets? The Lottery Expected Value Calculator helps you find out.
    </p>
    <ExpectedValueCalculator />
    <h2>FAQ</h2>
    <section itemScope itemType={"https://schema.org/FAQPage"}>
      <FAQItem question="How Accurate Is The Expected Value Calculator?">
        <strong>Lottolibrary's expected value calculator gives a good estimation, but not completely
          accurate.</strong> Some of the factors that can lead to inaccuracies:
        <ol>
          <li>For simplicity's sake the expected value calculator deals only with the jackpot prize: it does not take
            into account smaller prizes, which can slightly increase the expected value.
          </li>
          <li>The calculations assume that the number of winning tickets follows a binomial distribution.</li>
        </ol>
      </FAQItem>
      <FAQItem question="How Does The Number Of Tickets Sold Effect The Expected Value?">
        <strong>The bigger the number of tickets sold, the smaller the expected value will be.</strong> The more tickets
        are sold, the more likely it will be to have multiple winners. If there are multiple winners, the jackpot is
        split among them, so the winnings will be smaller, and the expected value decreases.
      </FAQItem>
      <FAQItem question="How Does The Price Of A Lottery Ticket Effect The Expected Value?">
        <strong>The higher the cost of buying a ticket is the lower the expected value will be.</strong>
      </FAQItem>
      <FAQItem question="What Are The Odds of Winning The Lottery?">
        If you are looking for a lottery odds calculator, we have a <Link to="/lottery-odds-calculator/">Lottery Odds
        Calculator Tool</Link> to help you out.
      </FAQItem>
      <FAQItem question="Which Lottery Game Has The Best Odds?">
        If you are looking for a comprehensive comparision of lottery games, you should definitely check out our <Link
        to="/best-odds/">Lottery Odds Comparision Tool</Link>. It has an extensive database of the winning odds of all
        the lottery games in the world.
      </FAQItem>
      <FAQItem question="For What Games Can the Lottery Expected Value Calculator Find the Expected Value?">
        <strong>Using this calculator you can get the odds for any lottery game.
          We've included presets for the most popular games like <a href="https://powerball.com" target="_blank"
                                                                    rel="noreferrer">Powerball</a>, <a
            href="https://www.megamillions.com"
            target="_blank" rel="noreferrer">Mega
            Millions</a>, Pick3, Pick4, Hot Lotto, <a href="https://www.euro-millions.com/" target="_blank"
                                                      rel="noreferrer">
            Euromillions</a>, <a href="https://www.luckyforlife.us/" target="_blank" rel="noreferrer"> Lucky for
            Life</a> and <a
            href="https://www.national-lottery.co.uk/games/thunderball" target="_blank"
            rel="noreferrer">Thunderball</a>.</strong><br />
        You are not limited to these presets though: by choosing the <code>Custom Game</code> option you can tweak the
        parameters and see how the expected value changes or check the odds of any theoritical game.
      </FAQItem>
      <FAQItem question="How Much Does It Cost to Use the Lottery Expected Value Calculator? Is It a Free Tool?">
        <strong><Link to="/">LottoLibrary.com</Link>'s lottery expected value calculator tool is free to
          use.</strong> There are no hidden fees or charges.<br />
        <i>Please note: We might show you 3<sup>rd</sup> party ads or affiliate links. You can read more about this in
          our <Link to="/terms-and-conditions/">T&amp;C document</Link>.</i>
      </FAQItem>
    </section>
    <h2>Disclaimer</h2>
    <section>
      This tool is intended for informational, educational, and entertainment purposes only.
    </section>
    <h2>Similar Tools</h2>
    <section>
      <ul>
        <li>
          <Link to="/lottery-number-generator/">Lottery Number Generator</Link>
        </li>
        <li>
          <Link to="/lottery-tax-calculator/">Lottery Tax Calculator</Link>
        </li>
        <li>
          <Link to="/lottery-annuity-calculator/">Lottery Annuity Payout Calculator</Link>
        </li>
        <li>
          <Link to="/best-odds/">Lottery Odds Comparison</Link>
        </li>
        <li>
          <Link to="/lottery-odds-calculator/">Lottery Odds Calculator</Link>
        </li>
      </ul>
    </section>
    <h2>Related Articles</h2>
    <section>
      <ul>
        <li>
          <Link to="/can-you-predict-the-lottery/">Can You Predict The Lottery?</Link>
        </li>
        <li>
          <Link to="/are-lotteries-rigged/">Are Lotteries Rigged?</Link>
        </li>
      </ul>
    </section>
    <h2>Sources</h2>
    <section>
      <ul>
        <li>
          <a href="https://en.wikipedia.org/wiki/Probability_theory" target="_blank" rel="noreferrer">Probability theory</a>
        </li>
        <li>
          <a
            href="https://en.wikipedia.org/wiki/Discrete_uniform_distribution"
            target="_blank" rel="noreferrer">Discrete uniform distribution</a>
        </li>
        <li>
          <a href="https://en.wikipedia.org/wiki/Combinatorics" target="_blank" rel="noreferrer">Combinatorics</a>
        </li>
      </ul>
    </section>
  </Layout>

export default LotteryExpectedValueCalculator

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
