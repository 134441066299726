import React, { useRef, useState } from "react"
import GameOptions from "../GameOptions"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import Paper from "@material-ui/core/Paper/Paper"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"
import InputAdornment from "@material-ui/core/InputAdornment"
import odds from "../odds"
import Button from "@material-ui/core/Button"


const ExpectedValueCalculator = ({ gameOptions }) => {
  const [options, setOptions] = useState({ display: false })
  const [numberOfTicketsSold, setNumberOfTicketsSold] = useState(10000000)
  const [displayExpectedValue, setDisplayExpectedValue] = useState(false)
  const [costOfTicket, setCostOfTicket] = useState(2)
  const [jackpotValue, setJackpotValue] = useState(100000000)
  const settingsRef = useRef(null)

  const updateOptions = () => {
    setOptions({
      rnd: Math.random(),
      ...gameOptions,
    })
  }

  const calculateExpectedValue = () => {
    updateOptions()
    window.scrollTo(0, settingsRef.current.offsetTop)
    setDisplayExpectedValue(true)
  }

  const expectedValue = (max, count, extraCount, numberOfTicketsSold, jackpotValue, costOfTicket) => {
    try {
      const oddsOfWinning = 1 / odds(max, count, count) / odds(max, extraCount, extraCount)
      return (1 - (1 - oddsOfWinning) ** numberOfTicketsSold) / numberOfTicketsSold * jackpotValue - costOfTicket
    } catch {
      return "Calculating..."
    }
  }

  return (
    <Paper elevation={1}>
      <Box p={4}>
        <Typography ref={settingsRef} variant="h4" component="h2">Expected Value Calculator</Typography>
        <Box p={2}>
          <GameOptions />
        </Box>
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Number of tickets sold"
                type="number"
                min={1}
                defaultValue={numberOfTicketsSold}
                onChange={(e) => setNumberOfTicketsSold(parseInt(e.target.value))}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Jackpot value"
                type="number"
                min={1}
                defaultValue={jackpotValue}
                onChange={(e) => setJackpotValue(parseInt(e.target.value))}
                InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Cost of a ticket"
                type="number"
                min={1}
                defaultValue={costOfTicket}
                onChange={(e) => setCostOfTicket(parseInt(e.target.value))}
                InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Button onClick={calculateExpectedValue} variant="contained" color="primary">Calculate!</Button>
            </Grid>
            {displayExpectedValue &&
            <Grid item xs={12} alignContent="center">
              <Typography align="center" variant="h5">Expected Value:</Typography>
              <Typography align="center" variant="h6">
                {
                  new Intl.NumberFormat(
                    "en-US",
                    { style: "currency", currency: "USD" },
                  ).format(
                    expectedValue(
                      options.max,
                      options.count,
                      options.extraCount,
                      numberOfTicketsSold,
                      jackpotValue,
                      costOfTicket,
                    ))
                }
              </Typography>
            </Grid>
            }
          </Grid>
        </Box>
      </Box>
    </Paper>
  )

}
const mapStateToProps = ({ gameOptions }) => ({ gameOptions })

ExpectedValueCalculator.propTypes = {
  gameOptions: PropTypes.shape(
    {
      count: PropTypes.number,
      max: PropTypes.number,
      extraCount: PropTypes.number,
      extraMax: PropTypes.number,
      unique: PropTypes.bool,
    },
  ),
}
export default connect(mapStateToProps)(ExpectedValueCalculator)
